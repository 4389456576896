<template>
    <div>
        <div class="container">
            <el-row>
                <el-col :span="24">
                    <div class="app-filter-region">
                        <el-button size="small" type="primary" icon="el-icon-plus" @click="add">{{$t('m.pubilc.tinajia')}}</el-button>
                    </div>
                </el-col>
                <el-row class="eltable cz-table">
                    <el-table
                            ref="multipleTable"
                            :data="tableData"
                            tooltip-effect="dark"
                            style="width: 100%">
                        <el-table-column
                                :label="$t('m.goods.GroupName')"
                                prop="industryName"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="number"
                                label="模板数量">
                        </el-table-column>
                        <el-table-column
                                prop="createTime"
                                :label="$t('m.goods.creationTime')"
                        >
                        </el-table-column>
                        <el-table-column :label="$t('m.pubilc.caozuo')" width="200" align="center">
                            <template slot-scope="scope">
                                <el-button type="text"
                                           size="mini"
                                           class="action-button"
                                           @click="handleEdit(scope.$index, scope.row)">{{$t('m.pubilc.bianji')}}</el-button>

                                <el-popover
                                        class="delete-popconfirm"
                                        placement="left"
                                        width="170"
                                        trigger="click"
                                        v-model="scope.row.showDeleteModal">
                                    <p>{{$t("m.article.quedingshanchushangpinfenzu")}}</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" @click="scope.row.showDeleteModal = false">
                                            {{$t("m.article.cancel")}}
                                        </el-button>
                                        <el-button type="primary" size="mini"
                                                   @click="handleDelete(scope.$index, scope.row)">
                                            {{$t("m.article.confirm")}}
                                        </el-button>
                                    </div>
                                    <el-button type="text"
                                               slot="reference"
                                               size="mini">{{$t('m.pubilc.shanchu')}}</el-button>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row  style="margin-top: 20px" type="flex" justify="space-between">
                    <div>

                    </div>
                    <el-row type="flex">
                        <div class="select-text">{{$t("m.pubilc.gong")}} {{total}} {{$t("m.pubilc.tiaoye")}} {{pageSize}} {{$t("m.pubilc.tiao")}}</div>
                        <el-pagination background layout="prev, pager, next" :total="total" :current-page="current_page"
                                       :page-size="pageSize" @current-change="pagechange">
                        </el-pagination>
                    </el-row>
                </el-row>
            </el-row>
        </div>
    </div>
</template>

<script>
    import {getAllIndustries,updateIndustryName,addIndustryName,deleteIndustry} from "../../../request/api/template";
    import pageTitle from "components/manager/page_title.vue";
    export default {
        name: 'material',
        components: { pageTitle },
        data() {
            return {
                tableData: [],
                pageSize: 10,
                total: 0,
                current_page: 1,
            };
        },
        created() {
            this.requestGroupData()

        },
        methods: {
            //查询分组
            requestGroupData(pageNum = 1) {
                this.tableData = []
                getAllIndustries({
                    pageNum:pageNum,
                    pageSize: this.pageSize,
                }).then(res=>{
                    if(res.code == 200){
                        this.tableData = res.data.list;
                        this.total = res.data.total;
                    }
                })
            },
            //删除商品分组
            handleDelete(index, row) {
                deleteIndustry({industryId: row.id}).then(res=>{
                    if(res.code == 200){
                        this.requestGroupData()
                    }else{
                        this.$notify.error({
                            title: this.$t('m.pubilc.tips'),
                            message: res.message,
                        });
                    }
                })
            },

            // 分页点击改变事件
            pagechange(e) {
                this.current_page = e
                this.requestGroupData(e)
            },

            add() {
                let _this = this;
                this.$prompt(this.$t('making.compose.groupTemplate.init.input'),
                    this.$t('making.compose.groupTemplate.init.title'), {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        inputValidator: (val)=>{if (!val)return this.$t('making.compose.groupTemplate.init.e0');
                            if (val.length > 10)return this.$t('making.compose.groupTemplate.init.e1');},
                        beforeClose:(action,instance,done)=>{
                            if (action === 'confirm') {
                                let data = {name:instance.inputValue};
                                addIndustryName(data).then(res=>{
                                    _this.$MESSAGE(res,()=>{
                                        done();
                                        _this.requestGroupData();
                                    })
                                })
                            } else {
                                done();
                            }
                        }
                    })
            },

            handleEdit(index, row) {
                let _this = this;
                this.$prompt(this.$t('making.compose.groupTemplate.init.input'),
                    this.$t('making.compose.groupTemplate.init.title'), {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        inputValue:row.industryName,
                        inputValidator: (val)=>{if (!val)return this.$t('making.compose.groupTemplate.init.e0');
                            if (val.length > 10)return this.$t('making.compose.groupTemplate.init.e1');},
                        beforeClose:(action,instance,done)=>{
                            if (action === 'confirm') {
                                let data = {industryId:row.id,name:instance.inputValue};
                                updateIndustryName(data).then(res=>{
                                    _this.$MESSAGE(res,()=>{
                                        done();
                                        _this.requestGroupData();
                                    })
                                })
                            } else {
                                done();
                            }
                        }
                    })
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    @import "../../../assets/style/table";
    .el-input {
        width: auto;
    }

    .el-button--mini {
        padding: 5px 10px;
    }

    .container {
        padding: 15px;
        min-width: 750px;
        min-height: 500px;
        box-shadow: none;
        background-color: white;
    }

    .app-filter-region {
        margin-bottom: 15px;
        float: right;
    }

    .eltable {
        margin-top: 20px;
        overflow: visible;
    }

    .eltable > .el-table th {
        background-color: #f8f8f8;
    }

    .eltable > .cell {
        overflow: visible;
    }

    .select-text {
        line-height: 40px;
        margin-right: 10px;
    }

    .goods-name {
        color: #155bd4;
    }
</style>
