import {request,POST,requestJSON} from '../request.js'


/**
 * 获取行业列表(有分页)
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getAllIndustries = (data)=> {
    return POST('/template/admin/getIndustryList',data);
};

/**
 * 更新行业名称
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const updateIndustryName = (data)=> {
    return POST('/template/admin/updateIndustryName',data);
};

/**
 * 新增行业名称
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const addIndustryName = (data)=> {
    return POST('/template/admin/addIndustryName',data);
};

/**
 * 删除行业
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const deleteIndustry = (data)=> {
    return POST('/template/admin/deleteIndustry',data);
};

/**
 * 获取行业列表(无分页)
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getIndustries = (data)=> {
    return POST('/template/admin/getAllIndustries',data);
};


/**
 * 获取模板列表
 * @param data
 * @returns {*}
 * @author Wuxr
 */
// export const getTempList = (data)=> {
//     return POST('/template/template/getList',data);
// };
export const getTempList = (data)=> {
    return POST('/template/admin/getTemplateList',data);
};

/**
 * 获取模板详情
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getTemplateInfo = (data)=> {
    return POST('/template/admin/getTemplateInfo',data);
};

/**
 * 获取微信代码模板下拉
 * @param data
 * @returns {*}
 * @author Wuxr
 */

export const getTemplateOptionList = (data)=> {
    return POST('/wechat/admin/getTemplateOptionList',data);
};


/**
 * 编辑微信模板相关内容
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const saveTemplateInfo = (data)=> {
    return requestJSON({
        method: 'post',
        url: '/template/admin/saveTemplateInfo',
        data: data
    })
};

/**
 * 更新序号
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const updateSerialNumber = (data)=> {
    return POST('/template/admin/updateSerialNumber',data);
};


/**
 * 下架模板
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const offShelfTemplate = (data)=> {
    return POST('/template/admin/offShelfTemplate',data);
};

/**
 * 删除模板
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const removeTemplate = (data)=> {
    return POST('/template/admin/removeTemplate',data);
};

/**
 * 更新行业
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const updateIndustry = (data)=> {
    return POST('/template/admin/updateIndustry',data);
};

/**
 * 获取小程序下拉
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getMiniProOptionList = (data)=> {
    return POST('/merchant/admin/getMiniProOptionList',data);
};



//--------------------以上为总后台的接口-----------------------


/**
 * 获取行业空模板
 * @param data
 * @returns {*}
 * @author TreeJohn
 */
export const getBlankTemplate = (data)=> {
    return POST('/template/template/getBlankTemplate',data);
};

/**
 * 获取模板详情
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getTempItem = (data)=> {
    return POST('/template/template/getInfo',data);
};

/**
 * 获取模板价格SKU
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getSku = (data)=> {
    return POST('/template/templateSku/getSku',data);
};
